import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Head from "../components/head"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"

const NotFound = () => {
  return (
    <Layout isHome={false}>
      <Head title="404" />
      <h2>Page Not Found</h2>
      <p>Sorry, we couldn't find this page. </p>
      <p>
        <Link to="/">
          Here's the way home <FontAwesomeIcon icon={faHome} />
        </Link>
      </p>
    </Layout>
  )
}

export default NotFound
